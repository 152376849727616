import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PortableTextContent from '../components/portableTextContent';

const AboutPage = ({ data }) => {
  const { sanityPage: aboutPageContent } = data;

  return (
    <Layout
      headerImage={aboutPageContent.headerImage.asset}
      headerText={aboutPageContent.title}
    >
      <section>
        <PortableTextContent rawContent={aboutPageContent._rawBody} />{' '}
      </section>
    </Layout>
  );
};

export default AboutPage;

export const AboutPageQuery = graphql`
  query AboutPage($id: String!) {
    sanityPage(id: { eq: $id }) {
      id
      title
      _rawBody
      headerImage {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
